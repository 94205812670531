@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "inter";
    src: url("../public/fonts/Inter-Light.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "playfair";
    src: url("../public/fonts/PlayfairDisplay-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }
}

#video {
  clip-path: inset(1px 1px);
}
